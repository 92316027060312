import { useState } from 'react'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import CurrencyInput from '../../components/form/CurrencyInput/CurrencyInput'
import { isAnyBlank } from '../../utils/string'
import FormaPagoSelector from '../../components/form/FormaPagoSelector/FormaPagoSelector'
import AlumnoSelector from '../../components/form/AlumnoSelector/AlumnoSelector'
import HoraSelector from '../../components/form/HoraSelector/HoraSelector'
import FechaSelector from '../../components/form/FechaSelector/FechaSelector'
import css from './ModalNuevoIngresoAlumno.module.css'
import { dateToString, dateToTimeString } from '../../utils/date'

const ModalNuevoIngresoAlumno = ({
  open,
  onClose,
  onSubmit,
})=> {

  const [alumno, setAlumno] = useState('')
  const [concepto, setConcepto] = useState('')
  const [formaPago, setFormaPago] = useState('')
  const [importe, setImporte] = useState('')
  const [fecha, setFecha] = useState(null)
  const [hora, setHora] = useState(null)

  const isTransferencia = formaPago === 'TRANSFERENCIA'

  const isSubmitDisabled = isAnyBlank(alumno, formaPago, concepto, importe) || (isTransferencia && (!fecha || !hora))

  const handleSubmit = ()=> {
    const payload = { alumno: alumno.id, concepto, formaPago, importe }
    if (isTransferencia) payload.fecha = `${dateToString(fecha)} ${dateToTimeString(hora)}`
    onSubmit(payload)
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <DialogTitle>Nuevo ingreso</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Vas a añadir un nuevo ingreso.
        </DialogContentText>
        {isTransferencia && (
          <Alert className={css.alert} severity='info'>
            Es necesario indicar la fecha en la que se realizó la transferencia.
          </Alert>
        )}
        <AlumnoSelector 
          className={css.input}
          name='alumno'
          label='Alumno'
          value={alumno}
          onChange={(_, value)=> setAlumno(value)}
        />
        <TextField
          className={css.input}
          fullWidth
          name='concepto'
          label='Concepto'
          variant='standard'
          value={concepto}
          onChange={e=> setConcepto(e.target.value)}
        />
        <FormaPagoSelector
          className={css.input}
          name='formaPago'
          label='Forma de pago'
          value={formaPago}
          onChange={e=> setFormaPago(e.target.value)}
        />
        {isTransferencia &&  
          <>
            <FechaSelector    
              className={css.input}
              name='Fecha'
              label='Fecha'
              value={fecha}
              onChange={value => setFecha(value)} 
            />
            <HoraSelector   
              className={css.input} 
              name='Hora'
              label='Hora'
              value={hora} 
              onChange={value=> setHora(value)} 
            />
          </>
        }
        <CurrencyInput
          className={css.input}
          name='importe'
          label='Importe'
          value={importe}
          onChange={value=> setImporte(value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button 
          color='primary' 
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
        >
          Registrar ingreso
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalNuevoIngresoAlumno
