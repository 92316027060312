import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { getTarifasActivas } from '../../../utils/api/contrataciones'
import css from './TarifaSelector.module.css'

const TarifaSelector = ({
  className = '',
  name,
  label,
  value,
  extraOption,
  onChange,
}) => {
  const notification = useNotification()

  const { data: tarifaList = [] } = useQuery({
    queryKey: ['datos', 'tarifas', 'list', 'active'], 
    queryFn: () => getTarifasActivas()
    .then(datos => datos
      .map(t=> t.nombre)
      .sort((t1, t2) => t1.localeCompare(t2))
    )
    .catch(err => {
      notification.error({ title: 'Error al recuperar las tarifas', content: err })
      return []
    })
  })

  const hasExtraOption = extraOption && !tarifaList.includes(extraOption)

  return (
    <FormControl
      className={`${className} ${css.main}`}
      variant='standard'
      fullWidth
    >
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        name={name}
        labelId={name}
        id={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {hasExtraOption && (
          <MenuItem key={extraOption} value={extraOption}>
            {extraOption}
          </MenuItem>
        )}
        {tarifaList.map(tarifa => (
          <MenuItem key={tarifa} value={tarifa}>
            {tarifa}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default TarifaSelector
