import { useEffect, useState } from "react"
import useDebounce from "./useDebounce"


const getScreenSize = (width)=> {
  if (width > 1500) return 'xl'
  if (width > 1000) return 'lg'
  if (width > 650) return 'md'
  if (width > 450) return 'sm'
  return 'xs'
}

const isScreenBigger = (targetSize, currentSize)=> {
  if (targetSize === 'xl') return false
  if (targetSize === 'lg') return ['xl'].includes(currentSize)
  if (targetSize === 'md') return ['xl', 'lg'].includes(currentSize)
  if (targetSize === 'sm') return ['xl', 'lg', 'md'].includes(currentSize)
  if (targetSize === 'xs') return ['xl', 'lg', 'md', 'sm'].includes(currentSize)
}

const useResponsive = () => {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  
  useEffect(()=> {
    const handleResize = ()=> setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return ()=> window.removeEventListener('resize', handleResize)
  }, [])
  
  const width = useDebounce(screenWidth, 100)
  const size = getScreenSize(width)

  return {
    width,
    size,
    isScreenBiggerThan: (targetSize)=> isScreenBigger(targetSize, size)
  }

}

export default useResponsive