import { Button, Dialog, DialogActions, DialogContent } from "@mui/material"
import css from "./ModalGaleriaCliente.module.css"

const ModalGaleriaCliente = ({ open, onClose, url }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>
      <img className={css.image} src={url} alt="Imagen completa cliente" />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary">
        Cerrar
      </Button>
    </DialogActions>
  </Dialog>
)

export default ModalGaleriaCliente
