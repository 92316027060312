import { GET, POST, PUT } from '../request'

export const getAccesoClassfy = cliente => GET('/api/agora/classfy/acceso-classfy', { cliente })

export const createAccesoClassfy = ({ cliente, email }) => POST ('/api/agora/classfy/acceso-classfy', { cliente, email })

export const updateAccesoEmail = ({ id, email, verificado }) => PUT('/api/agora/classfy/acceso-classfy/email', { id, email, verificado })
export const updateAccesoPassword = ({ id, password }) => PUT('/api/agora/classfy/acceso-classfy/password', { id, password })
export const updateAccesoAccesos = ({ id, acceso_app, acceso_classfy, acceso_barbeibot }) => PUT('/api/agora/classfy/acceso-classfy/acceso', { id, acceso_app, acceso_classfy, acceso_barbeibot })

export const updateRestablecerPassword = id => PUT ('api/agora/classfy/acceso-classfy/reset-password', { id })
export const updateRestablecerVerificacion = id => PUT ('api/agora/classfy/acceso-classfy/reset-verificacion', { id })
