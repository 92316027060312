
import { useState } from 'react'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import Tabs from '../../../../components/Tabs/Tabs'
import { downloadCSVFile, getCSVContent } from '../../../../utils/csv'
import { dateSort, numberSort, textFilter } from '../../../../utils/table'
import { Button, Card, CardActions, CardContent, CardHeader, Fab, IconButton, Link } from '@mui/material'
import InformationTable from '../../../../components/Table/InformationTable'
import { format, formatForExport } from '../../../../utils/math'
import ModalNuevoIngresoPago from '../../../../modals/ModalNuevoIngresoPago/ModalNuevoIngresoPago'
import { createIngresoPago, createPago } from '../../../../utils/api/contabilidad'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import { getFactura, getJustificantePago } from '../../../../utils/documents'
import { dateFromString, dateToMonth, dateToString, getToday } from '../../../../utils/date'
import ModalCerrarCaja from '../../../../modals/ModalCerrarCaja/ModalCerrarCaja'
import { cerrarCaja } from '../../../../utils/api/caja'
import css from './OperacionesCaja.module.css'
import ModalNuevoPagoAlumno from '../../../../modals/ModalNuevoPagoAlumno/ModalNuevoPagoAlumno'
import ModalNuevoIngresoAlumno from '../../../../modals/ModalNuevoIngresoAlumno/ModalNuevoIngresoAlumno'
import { createIngreso } from '../../../../utils/api/ingresos'

const CajaCardRow = ({ title, value })=> (
  <div className={css.cajaRow}>
    <p>{title}</p>
    <p>{value}</p>
  </div>
)


const Caja = ({ caja, ingresos })=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [showCloseModal, setShowCloseModal] = useState(false)

  const fecha = dateToString(getToday())

  const totalEfectivo = ingresos.filter(i=> i.formaPago === 'EFECTIVO').reduce((acc, i)=> acc + i.importe, 0)
  const totalTransferencia = ingresos.filter(i=> i.formaPago === 'TRANSFERENCIA').reduce((acc, i)=> acc + i.importe, 0)
  const totalTarjeta = ingresos.filter(i=> i.formaPago === 'TARJETA').reduce((acc, i)=> acc + i.importe, 0)

  const balanceFinal = (caja.balanceInicial || 0) + totalEfectivo

  const { isPending: isCajaClosing, mutate: closeCaja } = useMutation({
    mutationFn: cerrarCaja,
    onSuccess: ()=> {
      notification.success({ title: 'Caja cerrada', content: 'Se ha cerrado la caja correctamente' })
      queryClient.invalidateQueries({ 
        queryKey: ['contabilidad', 'caja', fecha],
      })
      setShowCloseModal(false)
    },
    onError: (err)=> {
      notification.error({ title: 'Error cerrando caja', content: err })
    }
  })

  const onCloseCaja = ({ importe })=> {
    if (isCajaClosing) return
    closeCaja({ balance: importe, fecha, observaciones: '' })
  }

  return (
    <div className={css.caja}>
      <Card elevation={4} className={css.cajaCard}>
        <CardHeader title='Caja de hoy' />
        <CardContent>
          <CajaCardRow title='Balance inicial' value={`${format(caja.balanceInicial || 0)} €`} />
          <CajaCardRow title='Efectivo actual' value={`${format(balanceFinal)} €`} />
        </CardContent>
        <CardActions className={css.cajaActions}>
          <Button variant='outlined' color='secondary' onClick={()=> setShowCloseModal(true)}>
            Cerrar caja
          </Button>
        </CardActions>
      </Card>
      <Card elevation={4} className={css.cajaCard}>
        <CardHeader title='Movimientos del día' />
        <CardContent>
          <CajaCardRow title='Efectivo' value={`${format(totalEfectivo)} €`} />
          <CajaCardRow title='Tarjeta' value={`${format(totalTarjeta)} €`} />
          <CajaCardRow title='Transferencias' value={`${format(totalTransferencia)} €`} />
        </CardContent>
      </Card>
      <ModalCerrarCaja
        open={showCloseModal}
        balanceTeorico={balanceFinal}
        onClose={()=> setShowCloseModal(false)}
        onSubmit={onCloseCaja}
      />
    </div>
  )
}

const Pagos = ({ isPagosLoading, pagos })=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [search, setSearch] = useState('')

  const [isOpenAddPago, setOpenAddPago] = useState(false)

  const [pagoSeleccionado, setPagoSeleccionado] = useState(null)

  const { isPending: isIngresoCreating, mutate: crearIngreso } = useMutation({
    mutationFn: createIngresoPago,
    onSuccess: ()=> {
      notification.success({ title: 'Ingreso creado', content: 'Nuevo ingreso añadido correctamente' })
      queryClient.invalidateQueries({ 
        queryKey: ['contabilidad', 'pagos', 'pending', 'recent'],
      })
      queryClient.invalidateQueries({ 
        queryKey: ['contabilidad', 'ingreso-alumno', 'list', 'day'],
      })
      setPagoSeleccionado(null)
    },
    onError: (err)=> {
      notification.error({ title: 'Error añadiendo ingreso', content: err })
    }
  })

  const { isPending: isPagoCreating, mutate: crearPago } = useMutation({
    mutationFn: createPago,
    onSuccess: ()=> {
      notification.success({ title: 'Pago creado', content: 'Nuevo pago añadido correctamente' })
      queryClient.invalidateQueries({ 
        queryKey: ['contabilidad', 'pagos', 'pending', 'recent'],
      })
      setOpenAddPago(false)
    },
    onError: (err)=> {
      notification.error({ title: 'Error añadiendo pago', content: err })
    }
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Alumno', 'Tipo pago', 'Fecha', 'Concepto', 'Importe Pago', 'Importe Pendiente'],
      data: pagos,
      transform: c=> ([
        c.alumno,
        c.tipo,
        c.fecha,
        c.concepto,
        `${formatForExport(c.importePago)} €`,
        `${formatForExport(c.importeRestante)} €`
      ])
    })
    downloadCSVFile(content, 'pagos-pendientes')
  }
  
  const filterSearch = alumno=> {
    return textFilter({ 
      object: alumno, 
      fields: ['alumno', 'tipo', 'concepto'],
      search: search
    })
  }

  const handleCrearIngreso = ({ id, formaPago, fecha, importe })=> {
    if (isIngresoCreating) return
    crearIngreso({ id, forma_pago: formaPago, fecha, importe })
  }

  const handleCrearPago = ({ alumno, concepto, importe })=> {
    if (isPagoCreating) return
    crearPago({ alumno, concepto, importe })
  }

  return (
    <>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Pagos pendientes'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Alumno', key: 'alumno', sortKey: 'alumnoNombre' },
          { title: 'Tipo pago', key: 'tipo', visibility: 'xl' },
          { title: 'Fecha', key: 'fecha', visibility: 'lg', sortFunction: dateSort },
          { title: 'Concepto', key: 'concepto' },
          { title: 'Importe', key: 'importe', sortFunction: numberSort },
          { title: 'Opciones', key: 'opciones', sortDisabled: true, align: 'center' }
        ]}
        data={pagos.filter(filterSearch).map((pago) => ({
          alumno: (
            <Link
              className={css.link}
              href={`/alumnos/${pago.alumnoId}/ficha`}
            >
              {pago.alumno}
            </Link>
          ),
          alumnoNombre: pago.alumno,
          tipo: pago.tipo,
          fecha: pago.fecha,
          concepto: pago.concepto,
          importe: `${format(pago.importeRestante)} €`,
          opciones: (
            <IconButton
              color='primary'
              size='small'
              onClick={() => setPagoSeleccionado(pago)}
            >
              <i className='material-icons'>point_of_sale</i>
            </IconButton>
          )
        }))}
        isFetching={isPagosLoading}
      />
      <Fab
        className={css.createButton}
        color='primary'
        onClick={()=> setOpenAddPago(true)}
      >
        <i className='material-icons'>add</i>
      </Fab>
      <ModalNuevoPagoAlumno
        open={isOpenAddPago}
        onClose={()=> setOpenAddPago(false)}
        onSubmit={handleCrearPago}
      />
      <ModalNuevoIngresoPago
        open={!!pagoSeleccionado}
        pago={pagoSeleccionado}
        onClose={()=> setPagoSeleccionado(null)}
        onSubmit={handleCrearIngreso}
      />
    </>
  )
}

const Operaciones = ({ isIngresosLoading, ingresos })=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [search, setSearch] = useState('')

  const [isOpenAddIngreso, setOpenAddIngreso] = useState(false)

  const { isPending: isIngresoCreating, mutate: crearIngreso } = useMutation({
    mutationFn: createIngreso,
    onSuccess: ()=> {
      notification.success({ title: 'Ingreso creado', content: 'Nuevo ingreso añadido correctamente' })
      queryClient.invalidateQueries({ 
        queryKey: ['contabilidad', 'ingreso-alumno', 'list', 'day'],
      })
      setOpenAddIngreso(false)
    },
    onError: (err)=> {
      notification.error({ title: 'Error añadiendo ingreso', content: err })
    }
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Alumno', 'Forma pago', 'Hora', 'Concepto', 'Importe', 'Factura'],
      data: ingresos,
      transform: c=> ([
        c.alumno,
        c.formaPago,
        c.hora,
        c.concepto,
        `${formatForExport(c.importe)} €`,
        c.codigoFactura ? c.codigoFactura : '--'
      ])
    })
    downloadCSVFile(content, 'ingresos-dia')
  }
  
  const filterSearch = alumno=> {
    return textFilter({ 
      object: alumno, 
      fields: ['alumno', 'formaPago', 'concepto'],
      search: search
    })
  }
  
  const handleViewJustificante = ingreso => {
    const url = getJustificantePago({
      academia: ingreso.centro,
      nombre: ingreso.alumno.split(' ')[0],
      alumno: ingreso.alumno,
      referencia: ingreso.id,
      mes: dateToMonth(dateFromString(ingreso.fecha)),
      fecha_emision: ingreso.fecha,
      importe: formatForExport(ingreso.importe),
    })
    window.open(url, '_blank')
  }

  const handleViewFactura = ingreso => {
    const url = getFactura({ 
      academia: ingreso.centro, 
      numero_factura: ingreso.codigoFactura,
      cliente: ingreso.nombreFactura,
      nif: ingreso.identificacionFactura,
      referencia: ingreso.id,
      fecha_emision: ingreso.fechaFactura,
      concepto: ingreso.conceptoFactura,
      importe: formatForExport(ingreso.importe),
    })
    window.open(url, '_blank')
  }

  const handleCrearIngreso = ({ alumno, concepto, formaPago, fecha, importe })=> {
    if (isIngresoCreating) return
    crearIngreso({ alumno, concepto, forma_pago: formaPago, fecha, importe })
  }

  return (
    <>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Ingresos del día'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        emptyText='No hay ingresos para el día de hoy'
        details={[
          { title: 'Alumno', key: 'alumno', sortKey: 'alumnoNombre' },
          { title: 'Forma pago', key: 'formaPago', visibility: 'xl' },
          { title: 'Hora', key: 'hora', visibility: 'lg' },
          { title: 'Concepto', key: 'concepto' },
          { title: 'Importe', key: 'importe', sortFunction: numberSort },
          { title: 'Recibo', key: 'recibo', sortDisabled: true, align: 'center' }
        ]}
        data={ingresos.filter(filterSearch).map((ingreso) => ({
          alumno: (
            <Link
              className={css.link}
              href={`/alumnos/${ingreso.alumnoId}/ficha`}
            >
              {ingreso.alumno}
            </Link>
          ),
          alumnoNombre: ingreso.alumno,
          formaPago: ingreso.formaPago,
          hora: ingreso.hora,
          concepto: ingreso.concepto,
          importe: `${format(ingreso.importe)} €`,
          recibo: (
            <>
              <IconButton
                color='primary'
                size='small'
                onClick={() => handleViewJustificante(ingreso)}
              >
                <i className='material-icons'>attach_file</i>
              </IconButton>
              {ingreso.codigoFactura && (
                <IconButton
                  color='primary'
                  size='small'
                  onClick={() => handleViewFactura(ingreso)}
                >
                  <i className='material-icons'>picture_as_pdf</i>
                </IconButton>
              )}
            </>
          )
        }))}
        isFetching={isIngresosLoading}
      />
      <Fab
        className={css.createButton}
        color='primary'
        onClick={()=> setOpenAddIngreso(true)}
      >
        <i className='material-icons'>add</i>
      </Fab>
      <ModalNuevoIngresoAlumno 
        open={isOpenAddIngreso}
        onClose={()=> setOpenAddIngreso(false)}
        onSubmit={handleCrearIngreso}
      />
    </>
  )
}


const OperacionesCaja = ({ caja, isPagosLoading, pagos, isIngresosLoading, ingresos })=> (
  <Tabs
    className={css.tabs}
    tabs={[
      { name: 'Caja', content: <Caja caja={caja} ingresos={ingresos} />},
      { name: 'Pagos', content: <Pagos isPagosLoading={isPagosLoading} pagos={pagos} /> },
      { name: 'Operaciones', content: <Operaciones isIngresosLoading={isIngresosLoading} ingresos={ingresos} /> }
    ]}
  />
)

export default OperacionesCaja