import { useState } from "react"
import { dateToString } from "../../../../../utils/date"
import { IconButton, Link } from "@mui/material"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useNotification } from "../../../../../context/NotificationManager/NotificationManager"
import { createFactura, getIngresosPendientesFactura } from "../../../../../utils/api/ingresos"
import InformationTable from "../../../../../components/Table/InformationTable"
import PageLoading from "../../../../../components/PageLoading/PageLoading"
import TableHeader from "../../../../../components/TableHeader/TableHeader"
import { dateTimeSort, numberSort, textFilter } from "../../../../../utils/table"
import { downloadCSVFile, getCSVContent } from "../../../../../utils/csv"
import { format, formatForExport } from "../../../../../utils/math"
import ModalNuevaFactura from "../../../../../modals/ModalNuevaFactura/ModalNuevaFactura"
import css from './Facturas.module.css'

const Facturas = ({ mes })=> {
  
  const notification = useNotification()
  const queryClient = useQueryClient()

  const [search, setSearch] = useState('')
  const [ingreso, setIngreso] = useState(null)

  const { isLoading, data: ingresoList=[] } = useQuery({
    queryKey: ['alumnos', 'contabilidad', 'ingreso-alumno', 'list', 'pending-invoice', mes], 
    queryFn: ()=> getIngresosPendientesFactura(mes)
      .then(datos=> datos.map(pago=> ({
        ...pago,
        fecha: dateToString(pago.fecha),
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los ingresos sin factura', content: err })
        return []
      })
  })
  
  const { isPending: isFacturaCreating, mutate: generarFactura } = useMutation({
    mutationFn: createFactura,
    onSuccess: ()=> {
      notification.success({ title: 'Factura creada', content: 'Se ha creado la factura correctamente' })
      queryClient.invalidateQueries(['alumnos', 'contabilidad', 'ingreso-alumno', 'list', 'pending-invoice'])
    },
    onError: (err)=> {
      notification.error({ title: 'Error creando factura', content: err })
    }
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: [
        'Alumno',
        'Fecha',
        'Concepto',
        'Centro',
        'Importe',
        'Forma de Pago',
      ],
      data: ingresoList,
      transform: c=> ([
        c.alumno,
        `${c.fecha} ${c.hora}`,
        c.concepto,
        c.centro,
        formatForExport(c.importe),
        c.formaPago,
      ])
    })
    downloadCSVFile(content, `ingresos-sin-factura-${mes}`)
  }

  const filterSearch = pago=> {
    return textFilter({ 
      object: pago, 
      fields: ['alumno', 'fecha', 'concepto', 'formaPago', 'importe'],
      search: search
    })
  }

  const handleCreateFactura = () => {
    if (isFacturaCreating) return
    generarFactura({ ingreso: ingreso.id })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Facturas pendientes de asignar'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Alumno', key: 'alumno', sortKey: 'alumnoNombre' },
          { title: 'Fecha', key: 'fecha', sortFunction: dateTimeSort },
          { title: 'Importe', key: 'importe', sortFunction: numberSort },
          { title: 'Forma de pago', key: 'tipoPago' },
          { title: 'Concepto', key: 'concepto' },
          { title: 'Opciones', key: 'opciones', sortDisabled: true}
        ]}
        data={ingresoList.filter(filterSearch).map((pago) => ({
          alumno: (
            <Link className={css.link} href={`/alumnos/${pago.alumnoId}/ficha`}>
              {pago.alumno}
            </Link>
          ),
          alumnoNombre: pago.alumno,
          fecha: `${pago.fecha} ${pago.hora}`,
          importe: `${format(pago.importe)} €`,
          tipoPago: pago.formaPago,
          concepto: pago.concepto,
          opciones: (
            <Link 
              className={css.facturaLink} 
              onClick={() => setIngreso(pago)}
            >
              Emitir factura
            </Link>
          )
        }))}
        isFetching={isLoading}
      />
      <ModalNuevaFactura
        open={!!ingreso}
        onClose={()=> setIngreso(null)}
        onSubmit={handleCreateFactura}
      />
    </PageLoading>
  )
}

export default Facturas